import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import "./css/shortmovie.styl"

const ShortMovie = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale

  return (
    <Layout location={location}>
      <SEO
        location={location}
        lang={language}
        title={language !== "ja" ? "Short Movie" : "ショートムービー"}
        keywords={[`ショートムービー`, `shortmovie`, `放射線像`, `Autoradiograph`]}
        description={language !== 'ja' ? `Autoradiograph project short movies and introduction of how to take Autoradiographs.` : `放射線像プロジェクトのショートムービーや放射線像の撮像方法を紹介します。`}
      />
      <section className="component">
        <ul className="shortmovie-card">
          <li>
            <iframe
              src="https://player.vimeo.com/video/461631734"
              frameBorder="0"
              width="700"
              height="393"
              allow="fullscreen"
              allowFullScreen
              title="2012 - 2020"
            ></iframe>
            <div className="caption">
              <span className="num">2012 - 2020</span>
            </div>
          </li>
          <li>
            <iframe
              src={language !== "ja" ? "https://player.vimeo.com/video/289985456" : "https://player.vimeo.com/video/345137337"}
              frameBorder="0"
              width="700"
              height="393"
              allow="fullscreen"
              allowFullScreen
              title="How to take Autoradiograph"
            ></iframe>
            <div className="caption">{language !== "ja" ? "How to take Autoradiograph" : "放射線像の撮像について"}</div>
          </li>
          <li>
            <iframe
              src="https://player.vimeo.com/video/173840339"
              frameBorder="0"
              width="700"
              height="393"
              allow="fullscreen"
              allowFullScreen
              title="2015 - 2016"
            ></iframe>
            <div className="caption">
              <span className="num">2015 - 2016</span>
            </div>
          </li>
          <li>
            <iframe
              src="https://player.vimeo.com/video/180967760"
              frameBorder="0"
              width="700"
              height="393"
              allow="fullscreen"
              allowFullScreen
              title="2012 - 2014"
            ></iframe>
            <div className="caption">
              <span className="num">2012 - 2014</span>
            </div>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default ShortMovie
